<template>
  <div>
    <v-data-table :loading="loadingTable" :headers="headers" :items="reserves" sort-by="calories" class="elevation-1"
      :page-count="numberOfPagesPaginate" :page="currentPagePaginate" :server-items-length="totalStagesPaginate"
      v-model="selectedItens" @click:row="selectItem"
      :options.sync="options" :footer-props="{
        itemsPerPageText: 'Itens por pagina',
        itemsPerPageOptions: [5, 10, 15],
        showFirstLastPage: true,
      }">
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Reservas</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-badge class="mr-2" :content="bagFilter" :value="bagFilter" bordered color="error" overlap>
            <v-btn @click="dialogFilter = true" class="ml-2" rounded outlined large
              color="primary"><v-icon>{{ icons.mdiFilter }}</v-icon>Filtros</v-btn>
          </v-badge>
          <v-text-field class="mr-2" placeholder="Proucurar ID, Hóspede, Empresa, Solicitante ou Localizador externo" v-model="search"
            :append-icon="icons.mdiMagnify" single-line label="Localizar" outlined filled rounded dense hide-details
            @click:append="searchItems()" @change="searchItems()"></v-text-field>
          <v-spacer></v-spacer>
          <v-btn color="primary" dark rounded class="mb-2" @click="showDialogNewGuest()">
            <v-icon small>
              {{ icons.mdiPlus }}
            </v-icon>
            Nova Reserva
          </v-btn>
        </v-toolbar>
      </template>

      <template v-slot:item.requester="{ item }">
        {{ requester(item) }}
      </template>

      <template v-slot:item.guests="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <span dark v-bind="attrs" v-on="on">
              {{ firstGuest(item) }}
            </span>
          </template>
          <span>{{ allGuests(item) }}</span>
        </v-tooltip>
      </template>

      <template v-slot:item.status="{ item }">
        <v-chip color="info" v-if="item.status == 'confirmed'">
          {{ reserved(item) }}
        </v-chip>
        <v-chip color="success" v-if="item.status == 'checkin'">
          {{ reserved(item) }}
        </v-chip>
        <v-chip color="error" v-if="item.status == 'checkout'">
          {{ reserved(item) }}
        </v-chip>
        <v-chip color="secondary" v-if="item.status == 'canceled'">
          {{ reserved(item) }}
        </v-chip>
        <v-chip color="warning" v-if="item.status == 'no-show'">
          {{ reserved(item) }}
        </v-chip>
      </template>
      <template v-slot:item.room="{ item }">
        {{ qtdRoom(item) }}
      </template>
      <template v-slot:item.check_in="{ item }">
        {{ formatDate(item.check_in) }}
      </template>
      <template v-slot:item.check_out="{ item }">
        {{ formatDate(item.check_out) }}
      </template>
      <template v-slot:item.actions="{ item }">
        <div style=" white-space: nowrap;">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon :loading="item.loading_print" :disabled="item.loading_print" v-if="item.status != 'canceled'"
                class="" color="secondary" @click="printItem(item)" v-bind="attrs" v-on="on">
                <v-icon>
                  {{ icons.mdiPrinter }}
                </v-icon>
              </v-btn>
            </template>
            <span>Imprimir</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">

              <v-btn icon color="primary" class="" @click="editItem(item)" v-bind="attrs" v-on="on">
                <v-icon>
                  {{ icons.mdiPencilOutline }}
                </v-icon>
              </v-btn>
            </template>
            <span>Editar</span>
          </v-tooltip>

          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-if="item.status != 'canceled'" color="error" @click="cancelItem(item)" v-bind="attrs"
                v-on="on" :disabled="item.status != 'confirmed'">
                <v-icon>
                  {{ icons.mdiCloseCircleOutline }}
                </v-icon>
              </v-btn>
            </template>
            <span>Cancelar</span>
          </v-tooltip>

          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-if="item.status == 'confirmed'" color="success" @click="payment(item)" v-bind="attrs"
                v-on="on">
                <v-icon>
                  {{ icons.mdiCurrencyUsd }}
                </v-icon>
              </v-btn>
            </template>
            <span>Adiantamento</span>
          </v-tooltip>

        </div>
      </template>

      <template v-slot:no-data>
        <NoDataTable></NoDataTable>
      </template>
      <template v-slot:loading>
        Carregando...
      </template>
    </v-data-table>

    <v-card class="mt-2">
      <v-card-item>
        <v-card-subtitle>
          Clique no registro para visualizar a observação:
        </v-card-subtitle>
      </v-card-item>
      <v-card-text>
        <v-row>
          <v-col cols="12" class="text-center">
            <p v-text="selectedItens.length>0 ?selectedItens[0].obs:''"></p>
          </v-col>
        </v-row>
      </v-card-text>      
    </v-card>

    <!-- DIALOG FILTRO -->
    <v-dialog v-model="dialogFilter" width="700">
      <v-card>
        <BarTitleDialog title="FILTROS" @close="dialogFilter = false" />
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-select v-model="filter.status" clearable outlined label="Status" :items="itemsStatus"></v-select>
            </v-col>

          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-row>
            <v-col class="text-left">
              <v-btn text color="error" x-large @click="clearFilter()">limpar filtros</v-btn>
            </v-col>
            <v-col class="text-right">
              <v-btn text color="secondary" x-large @click="dialogFilter = false">FECHAR</v-btn>
              <v-btn @click="confirmFilter()" color="primary" class="ml-2" x-large>aplicar</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- DIALOG CANCELAR RESERVA -->
    <v-dialog v-model="dialogCancel" max-width="500px">
      <v-card>
        <BarTitleDialog @close="dialogCancel = false" title="Cancelar reserva" />
        <v-card-title class="text-h5 text-center">
          <v-row no-gutters>
            <v-col>Deseja realmente cancelar ?</v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <v-textarea v-model="justifyCancel" outlined label="Justificativa" rows="3"></v-textarea>
            </v-col>
          </v-row>
          <v-row v-if="userAdmin">
            <v-col>
              <v-select v-model="cancelCashId" :loading="loadingOpenedCashs" :disabled="loadingOpenedCashs" outlined
                label="Caixa" :items="cashItems" item-value="id" item-text='text'></v-select>
            </v-col>
          </v-row>
          <v-row v-if="loadingUserAdmin">
            <v-col class="text-center">
              <v-progress-circular indeterminate color="primary"></v-progress-circular>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn large color="blue darken-1" text @click="dialogCancel = false">
            fechar
          </v-btn>
          <v-btn large color="error" :loading="loadingCancel" @click="cancelItemConfirm()">
            Sim
          </v-btn>

        </v-card-actions>
      </v-card>
    </v-dialog>


    <DialogReserve v-model="dialogNewGuest" :in_data='editedItem' @out_data='saveConfirm' :title="formTitle" />
    <!-- <MsgDelete v-model="dialogDelete" message="Deseja realmente cancelar ?" @delete-yes="cancelItemConfirm()" /> -->
  </div>
</template>

<script>
// import MsgDelete from '@/components/MsgDelete.vue'
import NoDataTable from '@/components/NoDataTable.vue'
import {
  mdiClose,
  mdiDeleteOutline,
  mdiPencilOutline,
  mdiPlus,
  mdiCloseCircleOutline,
  mdiPrinter,
  mdiCurrencyUsd,
  mdiFilter,
  mdiMessageOutline
} from '@mdi/js'
import { mapActions, mapMutations } from 'vuex'
import DialogReserve from './_components/DialogReserve.vue'
import BarTitleDialog from '@/components/BarTitleDialog.vue'

export default {
  components: {
    NoDataTable,
    DialogReserve,
    BarTitleDialog,
  },
  data: () => ({
    selectedItens: [],
    loadingUserAdmin: false,
    userAdmin: false,
    cancelCashId: null,
    loadingOpenedCashs: false,
    cashItems: [],
    loadingCancel: false,
    justifyCancel: '',
    dialogCancel: false,
    dialogFilter: false,
    filter: {},
    search: '',
    dialogNewGuest: false,
    loadingTable: false,
    totalStagesPaginate: 0,
    numberOfPagesPaginate: 0,
    currentPagePaginate: 0,
    dialogDelete: false,
    dialog: false,
    loading: false,
    itemsStatus: [
      { text: 'Checkin', value: 'checkin' },
      { text: 'Checkout', value: 'checkout' },
      { text: 'Cancelado', value: 'canceled' },
      { text: 'Confirmado', value: 'confirmed' },
      { text: 'No-Show', value: 'no-show' },
    ],
    headers: [
      {
        text: 'ID',
        align: 'start',
        value: 'id',
      },
      {
        text: 'EMPRESA',
        value: 'company.fantasy_name',
      },
      {
        text: 'SOLICITANTE',
        value: 'requester',
      },
      {
        text: 'HÓSPEDE',
        value: 'guests',
      },
      {
        text: 'CATEGORIA',
        value: 'room_category.description',
      },
      {
        text: 'QUARTOS',
        value: 'room',
      },

      {
        text: 'CHECKIN',
        value: 'check_in',
      },
      {
        text: 'CHECKOUT',
        value: 'check_out',
      },
      {
        text: 'STATUS',
        value: 'status',
      },

      {
        text: '',
        align: 'end',
        value: 'actions',
        sortable: false,
      },
    ],
    reserves: [],
    editedIndex: -1,
    editedItem: {},
    editedDefault: {
      check_in: '',
      check_out: '',
      company_id: '',
      room: [],
      tariff_manual: 'not',
      with_iss: 'not',
    },
    options: {},
    tab: null,
    items: [
      {
        tab: 'One',
        content: 'Tab 1 Content',
      },
      {
        tab: 'Two',
        content: 'Tab 2 Content',
      },
    ],
    icons: {
      mdiPencilOutline,
      mdiDeleteOutline,
      mdiPlus,
      mdiClose,
      mdiCloseCircleOutline,
      mdiPrinter,
      mdiCurrencyUsd,
      mdiFilter,
      mdiMessageOutline
    },
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'Nova Reserva' : 'Editar Reserva'
    },
    bagFilter() {
      if (this.filter.status == null) {
        delete this.filter.status
      }
      return Object.keys(this.filter).length
    },
  },

  watch: {
    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },
    options: {
      handler() {
        this.initialize()
      },
    },
  },

  created() {
    this.initialize()
    //carrega o select rooms do dialog reserve
    this.loadRooms()
    //carrega o select categoria de quartos do dialog reserve
    this.loadRoomCategories()
    //carrega o select tariffs
    this.loadTariffs()
  },

  methods: {
    ...mapActions('select', ['loadRooms', 'loadRoomCategories', 'loadTariffs']),
    ...mapActions('reserve', [
      'getAll',
      'store',
      'update',
      'printVoucher',
      'getReserveItems',
      'cancelReserve',
      'getOpenedCashs',
      'getUser',
    ]),
    ...mapMutations('checkout', [
      'setCheckout',
      'setStage',
      'clearCheckoutSeparete',
      'setOriginPartialPayment',
      'setRoom',
      'clearCheckout',
    ]),
    ...mapMutations('reserve', ['setGuests', 'setGuestsUnknown']),
    initialize() {
      this.loadingTable = true
      this.selectedItens = []
      const { page, itemsPerPage } = this.options

      const payload = {
        itemsPerPage: itemsPerPage || 10,
        page,
        search: this.search,
        filter: this.filter,
      }
      this.getAll(payload)
        .then(response => {
          this.reserves = response.data.data
          this.totalStagesPaginate = response.data.total
          this.numberOfPagesPaginate = response.data.last_page
          this.currentPagePaginate = response.data.current_page
        })
        .finally(() => {
          this.loadingTable = false
        })
    },
    searchItems() {
      this.options = {}
      this.initialize()
    },
    requester(item) {
      if (item.requester == null) {
        if (item.company) {
          return item.company.fantasy_name
        } else if (item.guest.length > 0) {
          return item.guest[0].name
        } else if (item.guest_unknown.length > 0) {
          return item.guest_unknown[0].name
        }
      } else {
        return item.requester
      }
    },
    clearFilter() {
      this.filter = {}
    },
    confirmFilter() {
      this.initialize()
      this.dialogFilter = false
    },
    firstGuest(item) {
      if (item.guest.length > 0) {
        return item.guest[0].name
      } else if (item.guest_unknown.length > 0) {
        return item.guest_unknown[0].name
      }
      return ''
    },

    allGuests(item) {
      let guests = []
      item.guest.forEach(guest => {
        guests.push(guest.name)
      })

      item.guest_unknown.forEach(guest => {
        guests.push(guest.name)
      })

      return guests.join()
    },
    showDialogNewGuest() {
      this.editedItem = {
        ...this.editedDefault,
      }
      this.editedIndex = -1,
        this.setGuests([])
      this.setGuestsUnknown([])
      this.dialogNewGuest = true
    },
    payment(reserve) {
      this.getReserveItems(reserve).then(response => {
        let reserveItems = response.data

        reserveItems.forEach(item => {
          item.origin = 'reserve'
          item.pivot = { quantity: 1 }
          item.sale_price = item.value
          item.description = 'HOSPEDAGEM ' + this.formatDate(item.date_start) + ' - ' + this.formatDate(item.date_end)
        })

        this.clearCheckout()
        this.clearCheckoutSeparete()

        this.setCheckout(reserveItems)
        this.setStage('payment')

        const room = {
          category: reserve.room_category,
          description: 'reserva',
          current_accommodation: reserve,
        }

        this.setRoom(room)

        this.setOriginPartialPayment('reserve')
        this.$router.push({ name: 'checkout' })
      })
    },
    editItem(item) {
      this.editedIndex = this.reserves.indexOf(item)
      this.editedItem = item
      this.setGuests(item.guest)
      this.setGuestsUnknown(item.guest_unknown)

      this.dialogNewGuest = true
    },

    saveConfirm(guest) {
      if (this.editedIndex == -1) {
        this.reserves.push(guest) //adiciona no array
      } else {
        Object.assign(this.reserves[this.editedIndex], guest) // atualiza o guest no array
      }
    },

    cancelItem(item) {
      this.justifyCancel = ''
      this.dialogCancel = true
      this.editedItem = item
      this.userAdmin = false
      //verifica se o usuario tem perfil admin
      this.loadingUserAdmin = true
      this.getUser()
        .then(response => {
          if (response.data) {
            //se tiver perfil administrador
            if (response.data.role_id == 1) {
              this.userAdmin = true
            } else {
              this.userAdmin = false
            }
          }
        })
        .finally(() => {
          this.loadingUserAdmin = false
        })

      //carrega o select de caixas abertos
      this.loadingOpenedCashs = true
      this.getOpenedCashs()
        .then(response => {
          console.log(response.data)
          this.cashItems = response.data
        })
        .finally(() => {
          this.loadingOpenedCashs = false
        })
    },

    cancelItemConfirm() {
      const index = this.reserves.indexOf(this.editedItem)
      const data = {
        id: this.editedItem.id,
        justify_cancel: this.justifyCancel,
        cancel_cash_id: this.cancelCashId,
      }
      this.loadingCancel = true
      this.cancelReserve(data)
        .then(response => {
          Object.assign(this.reserves[index], response.data)
          this.dialogCancel = false
          this.$toast.success('Cancelado com sucesso !')
        })
        .catch()
        .finally(() => {
          this.loadingCancel = false
        })
    },
    qtdRoom(item) {
      if (item.room.length > 0) {
        return item.room.length
      }
      return item.qtd_room
    },
    reserved(item) {
      switch (item.status) {
        case 'confirmed':
          return 'Confirmado'
        case 'checkin':
          return 'Checkin'
        case 'checkout':
          return 'Checkout'
        case 'canceled':
          return 'Cancelado'
        case 'no-show':
          return 'No-Show'
      }
    },

    printItem(reserve) {
      reserve.loading_print = true
      this.printVoucher(reserve)
        .then(response => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]))
          var fileLink = document.createElement('a')

          fileLink.href = fileURL
          fileLink.setAttribute('download', 'voucher.pdf')
          document.body.appendChild(fileLink)
          fileLink.click()
        })
        .catch(response => {
          console.log(response)
        })
        .finally(() => {
          this.reserves.forEach(item => {
            item.loading_print = false
          })
          //armegagem para força a reação do vue
          let backup = this.reserves
          this.reserves = []
          this.reserves = backup
        })
    },
    closeDelete() {
      this.dialogDelete = false
      this.editedIndex = -1
    },
    formatDate(date) {
      if (date) {
        const result = new Date(date)
        return result.toLocaleDateString('pt-BR', {
          timeZone: 'UTC',
        })
      }
    },
    selectItem(item) {
      this.selectedItens = []
      this.selectedItens.push(item)
      console.log(this.selectedItens)
    },
  },
}
</script>

<style scoped>
.card-tab {
  min-height: 420px;
  min-width: 736px;
}

.tool_table_fam {
  height: 50px;
}
</style>
